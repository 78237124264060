import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  styled,
  useTheme,
} from "@mui/material";
import { Fragment } from "react";
import Campaigns from "./shared/Campaigns";
import DoughnutChart from "./shared/Doughnut";
import RowCards from "./shared/RowCards";
import StatCards from "./shared/StatCards";
import StatCards2 from "./shared/StatCards2";
import TopSellingTable from "./shared/TopSellingTable";
import UpgradeCard from "./shared/UpgradeCard";
import VerifyEmail from "./../VerifyEmail";
import useAuth from "app/hooks/useAuth";
import { Accordion } from "../wordpress/AccordionStyle";
import { useState } from "react";
import { use } from "echarts";
import { Link, useNavigate } from "react-router-dom";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginRight: ".5rem",
  textTransform: "capitalize",
}));

const SubTitle = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
}));

const H4 = styled("h4")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginBottom: "16px",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
}));
export const MyLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const StarterGuide = () => {
  const [currentOpen, setCurrentOpen] = useState(0);
  const navigate = useNavigate();

  return (
    <div>
      <br></br>
      <Accordion
        expanded={currentOpen === 1}
        onChange={(e, v) => setCurrentOpen((p) => (p === 1 ? 0 : 1))}
      >
        <AccordionSummary>1/ Setup your Midjourney token</AccordionSummary>
        <AccordionDetails>
          Go to{" "}
          <Button onClick={() => navigate("/credentials")}>
            API & Credentials
          </Button>{" "}
          to add the discord token. This step is very important for linking
          Aphonse and Midjourney
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={currentOpen === 2}
        onChange={(e, v) => setCurrentOpen((p) => (p === 2 ? 0 : 2))}
      >
        <AccordionSummary>2/ Add your first WordPress site</AccordionSummary>
        <AccordionDetails>
          Go to
          <Button onClick={() => navigate("/wordpress_sites")}>
            WordPress Sites
          </Button>{" "}
          Settings section and add your Wordpress site.
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={currentOpen === 3}
        onChange={(e, v) => setCurrentOpen((p) => (p === 3 ? 0 : 3))}
      >
        <AccordionSummary>3/ Create your first prompt</AccordionSummary>
        <AccordionDetails>
          Go to
          <Button onClick={() => navigate("/prompt_generator")}>
            Prompt Generator
          </Button>{" "}
          to create your first prompt. This will allow you to start creating
          content
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const DesclaimerSection = () => {
  return (
    <Grid container spacing={3}>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <Card sx={{ px: 3, py: 2, mb: 3 }}>
          <Title>Before to use Alphonse</Title>
          <br />
          <SubTitle>
            To fully enjoy the capabilities of Alphonse Ai, our advanced
            artificial intelligence tool, registration with the Midjourney
            service is required. We strongly recommend our users to choose the{" "}
            <MyLink to="https://docs.midjourney.com/docs/plans" target="_blank">
              Standard Plan
            </MyLink>{" "}
            option. By subscribing to this plan, you benefit from unlimited
            access to the features of Alphonse Ai, allowing you to explore its
            vast possibilities without constraint.
          </SubTitle>

          <br></br>
          <br></br>
          <Title>Midjourney</Title>
          <br />
          <SubTitle>
            Users of Midjourney are responsible for adhering to the Midjourney{" "}
            <MyLink
              to="https://docs.midjourney.com/docs/terms-of-service"
              target="_blank"
            >
              Terms of Use
            </MyLink>{" "}
            when generating images through their system. Any questions regarding
            image rights or usage should be directed to the Midjourney Team.
          </SubTitle>

          <br></br>
          <br></br>

          <Title>OpenAI</Title>
          <br />
          <SubTitle>
            To fully utilize the Alphonse Ai image generator, adding an OpenAI
            key is essential, unlocking all advanced features for more complex
            images. Create your key at the "API" section on the{" "}
            <MyLink to="https://platform.openai.com/api-keys" target="_blank">
              OpenAI website
            </MyLink>
            . Enter your OpenAI key on our{" "}
            <MyLink to="/wordpress_sites">configuration page</MyLink>.
          </SubTitle>
        </Card>
      </Grid>
    </Grid>
  );
};
const Analytics = () => {
  const { palette } = useTheme();
  const { user } = useAuth();

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <StatCards />
            <H4>Status</H4>
            <StatCards2 />
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card sx={{ px: 3, py: 2, mb: 3 }}>
              <Title>Starter Guide</Title>
              <StarterGuide />
            </Card>

            <UpgradeCard />
          </Grid>
        </Grid>

        <H4>Desclaimer</H4>

        <DesclaimerSection />
      </ContentBox>
    </Fragment>
  );
};

export default Analytics;
