import React, { useEffect, useState } from 'react';
import { AddCircle, Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  DialogActions,
  DialogContentText,
  Alert,
  Typography,
  Switch,
  MenuItem,
  Checkbox,
  FormControlLabel,
  ListSubheader,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { H2, H3 } from 'app/components/Typography';
import { LoadingButton } from '@mui/lab';
import { getFunctions, httpsCallable } from 'firebase/functions';
import UpgradeDialog from 'app/views/UpgradePlanDialog';
import useAuth from 'app/hooks/useAuth';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { Accordion, AccordionSummary, AccordionDetails } from '../AccordionStyle';

const AddSiteDialog = ({ open, setOpen, setAddedSite }) => {
  const [url, setUrl] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState('');

  const addSite = async () => {
    setLoading(true);
    setErr('');

    try {
      let fn = httpsCallable(getFunctions(), 'manageUserData');
      const res = await fn({
        command: 'addSite',
        url,
        userName: username,
        password
      });

      setAddedSite((v) => !v);
      setOpen(false);
    } catch (e) {
      if (e.code === 'functions/invalid-argument') {
        setErr('Please enter valid information');
      } else if (e.code === 'functions/permission-denied') {
        setErr('Invalid username or password');
      }
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Add Website</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Enter the URL, username, and password for the WordPress site you want to connect to
        </DialogContentText>
        <br></br>
        <Stack>
          <TextField
            label="Site URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://example.com"
          />
          <br></br>
          <b style={{ marginBottom: '5px' }}>
            For multi-author setups, ensure using an admin account for comprehensive management.
          </b>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder=""
          />
          <br></br>

          <b style={{ marginBottom: '5px' }}>
            An application-specific password enhances security without compromising main access.
          </b>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Stack>

        {err && (
          <Alert style={{ marginTop: '15px' }} severity="error">
            {err}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton variant="contained" onClick={addSite} loading={loading}>
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const SiteView = ({ site, uid, presets, userData, active, onChange, deletedSite }) => {
  const [settings, setSettings] = useState(site.settings);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const changeActive = async (activate) => {
    try {
      let fn = httpsCallable(getFunctions(), 'manageUserData');
      const res = await fn({
        command: 'activateSite',
        activate,
        siteId: site.id
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteSite = async () => {
    setLoadingDelete(true);
    try {
      let fn = httpsCallable(getFunctions(), 'manageUserData');
      const res = await fn({ command: 'deleteSite', siteId: site.id });
      deletedSite();
    } catch (e) {
      console.log(e);
    }
    setLoadingDelete(false);
  };

  const saveSettings = async () => {
    setLoadingSave(true);
    const ref = doc(db, 'userData', uid, 'wordpress_sites', site.id);
    await updateDoc(ref, {
      settings: settings
    });
    setLoadingSave(false);
  };

  return (
    <Accordion expanded={active} onChange={onChange}>
      <AccordionSummary>
        <Typography>{site.url.replace('https://', '').replace('/wp-json', '')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={site.active}
              onChange={(e) => {
                changeActive(e.target.checked);
              }}
            />
          }
          label="Automatic image generation for scheduled posts"
        />
        <br></br>
        <br></br>
        <H3>Settings</H3>

        <Stack display="flex" justifyItems="center" justifyContent="center" alignItems="center">
          <Stack maxWidth="500px" gap="1.4rem">
            <FormControlLabel
              style={{}}
              control={
                <Checkbox
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      remove_images: e.target.checked
                    });
                  }}
                  checked={settings.remove_images}
                />
              }
              label="Remove existing images from post (if any)"
            />
            <TextField
              onFocus={(e) => {
                e.target.type = 'text';
              }}
              onBlur={(e) => {
                e.target.type = 'password';
              }}
              type="password"
              value={settings.openai_key}
              onChange={(e) => {
                setSettings({
                  ...settings,
                  openai_key: e.target.value
                });
              }}
              variant="outlined"
              name="openai_key"
              label="OpenAI API Key"
              fullWidth
            />

            <TextField
              select
              value={settings.selected_dc_cred}
              onChange={(e) => {
                setSettings({
                  ...settings,
                  selected_dc_cred: e.target.value
                });
              }}
              variant="outlined"
              label="Discord Credential"
              fullWidth
            >
              <MenuItem value={-1}>Please select a credential</MenuItem>
              {userData.dc_creds.map((cred, index) => (
                <MenuItem key={index} value={index}>
                  {cred.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              value={settings.cover_preset_id}
              onChange={(e) => {
                setSettings({
                  ...settings,
                  cover_preset_id: e.target.value
                });
              }}
              variant="outlined"
              label="Cover Image Preset"
              fullWidth
            >
              <MenuItem value="">Select a preset for cover image</MenuItem>
              {presets &&
                presets.map((preset) => (
                  <MenuItem key={preset.id} value={preset.id}>
                    {preset.name}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              select
              value={settings.article_preset_id}
              onChange={(e) => {
                setSettings({
                  ...settings,
                  article_preset_id: e.target.value
                });
              }}
              variant="outlined"
              label="Article Image Preset"
              fullWidth
            >
              <MenuItem value="">Select a preset for article images</MenuItem>
              {presets &&
                presets.map((preset) => (
                  <MenuItem key={preset.id} value={preset.id}>
                    {preset.name}
                  </MenuItem>
                ))}
            </TextField>

            <Stack direction="row" gap="1rem">
              <TextField
                value={settings.image_size[0]}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    image_size: [parseInt(e.target.value), settings.image_size[1]]
                  });
                }}
                variant="outlined"
                label="Image Width"
                fullWidth
                type="number"
              />

              <TextField
                value={settings.image_size[1]}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    image_size: [settings.image_size[0], parseInt(e.target.value)]
                  });
                }}
                variant="outlined"
                label="Image Height"
                fullWidth
                type="number"
              />
            </Stack>
            <Stack gap="1rem" flexDirection="row" alignItems="center">
              <TextField
                select
                value={settings.aspect_ratio}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    aspect_ratio: e.target.value
                  });
                }}
                variant="outlined"
                label="Image Aspect Ratio"
                fullWidth
                style={{ width: '50%' }}
              >
                <MenuItem value="--ar 1:1">1:1</MenuItem>
                <ListSubheader>Horizontal</ListSubheader>
                <MenuItem value="--ar 3:2">3:2</MenuItem>
                <MenuItem value="--ar 16:9">16:9</MenuItem>
                <MenuItem value="--ar 5:4">5:4</MenuItem>
                <MenuItem value="--ar 7:4">7:4</MenuItem>

                <ListSubheader>Vertical</ListSubheader>
                <MenuItem value="--ar 2:3">2:3</MenuItem>
                <MenuItem value="--ar 9:16">9:16</MenuItem>
                <MenuItem value="--ar 4:5">4:5</MenuItem>
                <MenuItem value="--ar 7:4">7:4</MenuItem>
              </TextField>

              <Stack direction="row" alignItems="center" justifyContent="center" width="50%">
                <FormControlLabel
                  style={{}}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setSettings({
                          ...settings,
                          activate_exif: e.target.checked
                        });
                      }}
                      checked={settings.activate_exif}
                    />
                  }
                  label="Activate EXIF"
                />
              </Stack>
            </Stack>

            <FormLabel id="radio-buttons-group-label">Number of Images to Generate</FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    checked={settings.h2_per_image == 0}
                    onChange={(e) => {
                      setSettings({
                        ...settings,
                        h2_per_image: 0
                      });
                    }}
                  />
                }
                label="Only Generate the cover photo"
              />
              <FormControlLabel
                value={2}
                control={
                  <Radio
                    checked={settings.h2_per_image == 2}
                    onChange={(e) => {
                      setSettings({
                        ...settings,
                        h2_per_image: 2
                      });
                    }}
                  />
                }
                label="Generate 1 image for every 2 H2 tags"
              />
              <FormControlLabel
                value={3}
                control={
                  <Radio
                    checked={settings.h2_per_image == 3}
                    onChange={(e) => {
                      setSettings({
                        ...settings,
                        h2_per_image: 3
                      });
                    }}
                  />
                }
                label="Generate 1 image for every 3 H2 tags"
              />
            </RadioGroup>
          </Stack>
        </Stack>

        <Stack
          gap="1rem"
          flexDirection="row"
          alignItems="center"
          justifyContent={'space-between'}
          marginTop={'2rem'}
        >
          <LoadingButton
            variant="outlined"
            color="primary"
            startIcon={<Delete />}
            loading={loadingDelete}
            onClick={deleteSite}
          >
            Delete Site
          </LoadingButton>

          <LoadingButton
            onClick={saveSettings}
            loading={loadingSave}
            variant="contained"
            color="primary"
          >
            Save Settings
          </LoadingButton>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const SingleSiteSettings = ({ uid, userData, presets }) => {
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [upMsg, setUpMsg] = React.useState(null);
  const [sites, setSites] = React.useState(null);
  const [addedSite, setAddedSite] = React.useState(false);
  const [activeSite, setActiveSite] = React.useState(-1);

  useEffect(() => {
    const getSites = async () => {
      const res = await getDocs(collection(db, 'userData', user.uid, 'wordpress_sites'));
      let nSites = [];
      res.docs.forEach((doc) => {
        nSites.push({ ...doc.data(), id: doc.id });
      });

      setSites(nSites);
    };

    getSites();
  }, [addedSite]);

  if (!userData) return null;

  return (
    <div>
      <AddSiteDialog setAddedSite={setAddedSite} open={open} setOpen={setOpen} />
      <UpgradeDialog msg={upMsg} setMsg={setUpMsg} />

      <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <H2>Your Sites</H2>
        <Button
          disabled={sites === null}
          color="primary"
          startIcon={<AddCircle />}
          onClick={() => {
            if (sites.length >= user.plan.wp_rest_api.website_limit) {
              setUpMsg(
                'You can add a maximum of ' +
                  user.plan.wp_rest_api.website_limit +
                  ' websites to your account. Please upgrade your plan to add more websites.'
              );
            } else {
              setOpen(true);
            }
          }}
        >
          Add Site
        </Button>
      </Stack>
      <br></br>

      {sites &&
        sites.map((site, index) => (
          <SiteView
            key={site.id}
            site={site}
            uid={uid}
            userData={userData}
            presets={presets}
            active={index === activeSite}
            onChange={() => setActiveSite((i) => (i === index ? -1 : index))}
            index={index}
            deletedSite={() => setAddedSite((v) => !v)}
          />
        ))}
    </div>
  );
};

export default SingleSiteSettings;
