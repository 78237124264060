import React from 'react';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Input,
  InputAdornment,
  Link,
  Radio,
  Tab,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  useMediaQuery
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate } from 'react-router-dom';
import VerifyEmail from '../VerifyEmail';
import useAuth from 'app/hooks/useAuth';
import { useEffect, useState } from 'react';
import useSettings from 'app/hooks/useSettings';
import { blue, red } from '@mui/material/colors';
import { H2, H3, H4, H5 } from 'app/components/Typography';
import { LoadingButton } from '@mui/lab';
import Loading from 'app/components/MatxLoading';
import { settings } from 'firebase/analytics';
import { db } from '../../../firebase';
import { doc, getDoc, getFirestore, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';

import UpgradeDialog from '../UpgradePlanDialog';
import { LinkOutlined } from '@mui/icons-material';
import AddCredDialog from './AddCredDialog';
import { useTheme } from '@emotion/react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  position: 'relative'
}));

const CardDiv = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  padding: '20px',
  boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
  borderRadius: '5px',
  flexDirection: 'column',
  width: '100%'
}));

const APIArea = (props) => {
  {
    const { user, updateAPIKey } = useAuth();
    const [key_show, setKeyShow] = useState('');
    const [allowedDomains, setAllowedDomains] = useState(null);
    const [opAddDomain, setOpAddDomain] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRegenerating, setIsRegenerating] = useState(false);
    const [domain, setDomain] = useState('');
    const [urlError, setUrlError] = useState(false);

    useEffect(() => {
      let key = user.acc.api_key;
      key = key.substring(0, 5);
      for (let i = 5; i < user.acc.api_key.length - 4; i++) {
        key += '*';
      }
      setKeyShow(key + user.acc.api_key.substring(user.acc.api_key.length - 4));
      console.log(key);
    }, [user]);

    useEffect(() => {
      if (props.userData) {
        setAllowedDomains(props.userData?.allowed_domains || []);
      }
    }, [props.userData]);

    const deleteDomain = async (index) => {
      setIsSaving(true);

      const fn = httpsCallable(getFunctions(), 'manageUserData');
      await fn({ command: 'removeDomain', toRemove: allowedDomains[index] });

      setIsSaving(false);
    };

    const regenerateKey = async () => {
      setIsRegenerating(true);
      const fn = httpsCallable(getFunctions(), 'regenerateAPIKey');
      const resp = await fn();

      updateAPIKey(resp.data.new_key);
      setIsRegenerating(false);
    };

    return (
      <div
        style={{
          width: '100%'
        }}
      >
        <H3>API key</H3>
        <p>
          Your API key is used to authenticate your requests to the API. You can view your API key
          below. You should keep this key secret, and only store it on your own servers. Your API
          key carries many privileges, so be sure to keep it secret!
        </p>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '70%',
                lg: '60%'
              }
            }}
          >
            <TextField
              disabled
              value={key_show}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      endIcon={<Icon>content_copy</Icon>}
                      onClick={() => {
                        navigator.clipboard.writeText(user.acc.api_key);
                      }}
                    ></Button>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </div>
        <p>
          If you believe your API key has been compromised, you can regenerate a new API key.
          Regenerating your API key will invalidate the old key.
        </p>
        <LoadingButton
          loading={isRegenerating}
          startIcon={<Icon>refresh</Icon>}
          onClick={regenerateKey}
        >
          Regenerate API key
        </LoadingButton>

        <br></br>
        <br></br>
        <H3>Allowed Domains</H3>
        <p>
          Your API key can only be used from domains that you whitelist. To whitelist a domain,
          enter its full URL below. Any subdomains of the whitelisted domain will also be allowed.
        </p>
        <p>
          To add a domain, use the <code>/checkValidity</code> API endpoint from the domain you wish
          to whitelist. View the full API documentation{' '}
          <Link
            href="https://app.swaggerhub.com/apis/faridulreza/Alphonse/1.0.0#/default/get_checkValidity"
            target="_blank"
          >
            here
          </Link>
          .
        </p>

        <br></br>
        <br></br>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <Table
            sx={{
              width: {
                xs: '100%',
                md: '70%',
                lg: '60%'
              }
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell width={'80%'}>Domain</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            {allowedDomains &&
              allowedDomains.map((domain, index) => (
                <TableRow>
                  <TableCell width={'80%'}>{domain.domain + ' (' + domain.ip + ')'}</TableCell>

                  <TableCell align="center">
                    <Button disabled={isSaving} onClick={() => deleteDomain(index)}>
                      <Icon>delete</Icon>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

            {!allowedDomains && (
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  <Loading />
                </TableCell>
              </TableRow>
            )}
            {allowedDomains && allowedDomains.length == 0 && (
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  No domains added
                </TableCell>
              </TableRow>
            )}
          </Table>
        </div>
      </div>
    );
  }
};

const CredentialArea = (props) => {
  const { user } = useAuth();
  const [creds, setCreds] = useState([]);
  const [selectedCred, setSelectedCred] = useState(null);
  const [opAddCred, setOpAddCred] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showChooseCred, setShowChooseCred] = useState(true);
  const [openAIkey, setOpenAIkey] = useState('');

  useEffect(() => {
    if (window.localStorage.getItem('showChooseCred') == 'false') setShowChooseCred(false);
  }, []);

  useEffect(() => {
    if (props.userData) {
      setCreds(props.userData.dc_creds);
      setSelectedCred(props.userData.selected_dc_cred);
      setOpenAIkey(props.userData.openai_key);
    }
  }, [props.userData]);

  useEffect(() => {
    const saveKey = async () => {
      await updateDoc(doc(getFirestore(), 'userData', user.uid), {
        openai_key: openAIkey
      });
    };

    if (openAIkey != props.userData?.openai_key) {
      const timeout = setTimeout(saveKey, 1000);
      return () => clearTimeout(timeout);
    }
  }, [openAIkey]);

  const setSelected = async (index) => {
    setIsSaving(true);

    await updateDoc(doc(getFirestore(), 'userData', user.uid), {
      selected_dc_cred: index
    });
    setIsSaving(false);
    setSelectedCred(index);
  };

  const deleteCredential = async (index) => {
    setIsSaving(true);

    let sl = selectedCred;

    if (creds.length == 1) sl = -1;
    else if (sl >= creds.length - 1) {
      sl = creds.length - 2;
    }

    const fn = httpsCallable(getFunctions(), 'manageUserData');
    await fn({
      command: 'removeUserCredential',
      toUpdate: {
        itemToRemove: creds[index],
        selected_dc_cred: sl
      }
    });

    setIsSaving(false);
  };

  return (
    <div>
      <AddCredDialog
        op={opAddCred}
        setData={(data) => {
          if (data) {
            setCreds([...creds, data]);
          }
          setOpAddCred(false);
        }}
      />
      <Grid container columnSpacing={2} rowSpacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: 'flex',
              marginBottom: '10px'
            }}
          >
            <H3 minWidth={'70%'}>OpenAI API key</H3>
          </div>

          <div style={{ width: '85%' }}>
            <TextField
              fullWidth
              type="password"
              onFocus={(e) => (e.target.type = 'text')}
              onBlur={(e) => (e.target.type = 'password')}
              placeholder="Enter your OpenAI API key here"
              value={openAIkey}
              onChange={(e) => {
                setOpenAIkey(e.target.value);
              }}
            />
          </div>
          <br></br>
          <br></br>

          <div
            style={{
              display: 'flex'
            }}
          >
            <H3 minWidth={'70%'}>Generation Credentials</H3>
            <Button
              style={{
                marginTop: '-4px'
              }}
              disabled={selectedCred === null}
              color="primary"
              startIcon={<Icon>add</Icon>}
              onClick={() => {
                if (user.plan.saas.dc_token_limit != 0) {
                  if (creds.length >= user.plan.saas.dc_token_limit) {
                    props.setPlanMsg(
                      'You have reached the limit of custom credentials for your plan'
                    );
                    return;
                  } else setOpAddCred(true);
                } else {
                  props.setPlanMsg('You need to upgrade your plan to add custom credentials');
                }
              }}
            >
              Add
            </Button>
          </div>
          <br></br>
          {selectedCred !== null && (
            <div>
              <Box>
                <Radio
                  disabled={isSaving}
                  checked={selectedCred == -1}
                  onChange={(e) => {
                    setSelected(-1);
                  }}
                />
                Use Administrative Credential
              </Box>

              {creds.map((cred, index) => (
                <Box key={cred.dc_token + cred.channel_id}>
                  <Radio
                    disabled={isSaving}
                    checked={selectedCred == index}
                    onChange={(e) => {
                      setSelected(index);
                    }}
                  />

                  <div
                    style={{
                      display: 'inline-block',
                      marginLeft: '10px',
                      minWidth: '60%'
                    }}
                  >
                    {cred.name}
                  </div>

                  <Button disabled={isSaving} onClick={() => deleteCredential(index)}>
                    <Icon>delete</Icon>
                  </Button>
                </Box>
              ))}
            </div>
          )}

          {selectedCred === null && <Loading />}
        </Grid>

        <Grid item xs={12} md={6} sx={{ marginTop: { xs: '20px', md: '0px' } }}>
          <H3>Need Help?</H3>
          <br></br>
          <div>
            <ul>
              <li>
                <Link
                  href={'https://www.androidauthority.com/get-discord-token-3149920/'}
                  target="_blank"
                >
                  How to get your discord token / salai token
                </Link>
              </li>

              <p>
                To get the <var>SERVER_ID</var> and <var>CHANNEL_ID</var>
              </p>
              <li>
                <Link
                  href="https://discord.com/blog/starting-your-first-discord-server"
                  target="_blank"
                >
                  create a discord server
                </Link>{' '}
                and{' '}
                <Link href="https://docs.midjourney.com/docs/invite-the-bot" target="_blank">
                  invite the midjourney bot
                </Link>
              </li>
              <p>
                when you click on a channel in your server in the browser expect to have the follow
                URL pattern
                <br></br>
                <br></br>
                <p style={{ lineBreak: 'anywhere' }}>
                  https://discord.com/channels/<var>SERVER_ID</var>/<var>CHANNEL_ID</var>
                </p>
              </p>
            </ul>
          </div>
        </Grid>
      </Grid>
      <br></br>
      <Checkbox
        inputProps={{
          'aria-label': 'Show Credential choosing dialog each time before generating image'
        }}
        checked={showChooseCred}
        onChange={(e) => {
          setShowChooseCred(e.target.checked);
          window.localStorage.setItem('showChooseCred', e.target.checked ? 'true' : 'false');
        }}
      />
      Show Credential choosing dialog each time before generating image.
    </div>
  );
};

export default Credential = () => {
  const { user } = useAuth();
  const [planMsg, setPlanMsg] = useState(null);
  const [currTab, setCurrTab] = useState('2');
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(getFirestore(), 'userData', user.uid), (doc) => {
      setUserData(doc.data());
    });

    return unsubscribe;
  }, [user]);

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  return (
    <div>
      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />

      <FlexBox style={{ marginTop: '25px', marginBottom: '35px' }}>
        <H2 style={{ position: 'absolute', top: '-15px', left: '12px' }}>API & Credentials</H2>
      </FlexBox>

      <FlexBox>
        <Box m={{ xs: 2, sm: 4, md: 5 }} width={{ sm: '70%' }}>
          <CardDiv>
            <TabContext value={currTab}>
              <Box>
                <TabList
                  onChange={(e, val) => {
                    setCurrTab(val);
                  }}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Credential" value="2" />
                  <Tab label="API key" value="1" />
                </TabList>
              </Box>

              <TabPanel value="1">
                <APIArea setPlanMsg={setPlanMsg} userData={userData} />
              </TabPanel>
              <TabPanel value="2">
                <CredentialArea setPlanMsg={setPlanMsg} userData={userData} />
              </TabPanel>
            </TabContext>
          </CardDiv>
        </Box>
      </FlexBox>
    </div>
  );
};
