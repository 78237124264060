function updatePrompt(values, plan) {
  if (!plan || !plan.saas.generator) return '';

  console.log(values);

  for (var key in values.data) {
    if (values.data.hasOwnProperty(key)) {
      if (values.data[key] === 'undefined' || values.data[key] === '$') {
        values.data[key] = null;
      }
    }
  }
  var selectedt2a = values.data['t2a'];
  var selectedt2b = values.data['t2b'];
  var selectedt2c = values.data['t2c'];
  var selectedt3a = values.data['t3a'];
  var selectedbackgroundphoto = values.data['backgroundphoto'];
  var selectedcolorsphoto = values.data['colorsphoto'];
  var selectedlighting = values.data['lighting'];
  var selectedweather1 = values.data['weather1'];
  var selectedweather2 = values.data['weather2'];
  var selectedday = values.data['day'];
  var selectedlight2 = values.data['light2'];
  var selectedexpression = values.data['expression'];
  var selectedfocus = values.data['focus'];
  var selectedlook = values.data['look'];
  var selectedviewmodel = values.data['viewmodel'];
  var selectedskin = values.data['skin'];
  var selectedt3d = values.data['t3d'];
  var selectedt3e = values.data['t3e'];
  var selectedt3f = values.data['t3f'];
  var selectedlens = values.data['lens'];
  var selectedt4b = values.data['t4b'];
  var selectedt4c = values.data['t4c'];
  var selectedt4d = values.data['t4d'];
  var selectedcamera = values.data['camera'];
  var selectedt4f = values.data['t4f'];
  var selectedt5a = values.data['t5a'];
  var selectedt5aa = values.data['t5aa'];
  var selectedt5b = values.data['t5b'];
  var selectedt5c = values.data['t5c'];
  var selectedt5d = values.data['t5d'];
  var selectedt5e = values.data['t5e'];
  var selectedcomics = values.data['comics'];
  var selectedcomicscolors = values.data['comicscolors'];
  var selectedcomicsdesign = values.data['comicsdesign'];
  var selectedDivider = values.data['Divider'] || ', ';
  var selectedFormat = values.data['formats'];
  var selectedOption = values.data['style'];
  var selectedCustom = values.data['custom'];
  var selectedQuality = values.data['quality'];
  var selectedWeight = values.data['Weight'];
  var nega = values.data['nega'];
  var seed = values.data['seed'];
  var subject = values.data['subject'];
  var url = values.data['url'];
  var version = values.data['version'];

  var text = '';
  let sortedKeyValues = [];
  for (let key in values.data) {
    sortedKeyValues.push([key, values.data[key]]);
  }
  sortedKeyValues.sort((a, b) => {
    return a[0].localeCompare(b[0]);
  });

  for (let key in sortedKeyValues) {
    let k = sortedKeyValues[key][0];
    let v = sortedKeyValues[key][1];
    if (k.startsWith('start-')) {
      if (text.length > 0) {
        text += ', ';
      }
      text += v;
    }
  }

  if (url) {
    text += url;
  }
  if (selectedt5a) {
    if (text.length > 0) {
      text += ' ';
    }
    text += selectedt5a;
  }
  if (selectedt3a) {
    if (text.length > 0) {
      text += ' ';
    }
    text += selectedt3a;
  }
  if (selectedOption) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedOption;
  }
  if (subject) {
    if (text.length > 0) {
      text += ' ';
    }
    text += subject;
  }
  if (selectedbackgroundphoto) {
    if (text.length > 0) {
      text += ' ';
    }
    text += selectedbackgroundphoto;
  }
  if (selectedCustom) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedCustom;
  }
  if (selectedlook) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedlook;
  }
  if (selectedviewmodel) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedviewmodel;
  }
  if (selectedfocus) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedfocus;
  }
  if (selectedexpression) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedexpression;
  }
  if (selectedcolorsphoto) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedcolorsphoto;
  }
  if (selectedlighting) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedlighting;
  }
  if (selectedweather1) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedweather1;
  }
  if (selectedweather2) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedweather2;
  }
  if (selectedday) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedday;
  }
  if (selectedlight2) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedlight2;
  }
  if (selectedt3d) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedt3d;
  }
  if (selectedt3e) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedt3e;
  }
  if (selectedcamera) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedcamera;
  }
  if (selectedlens) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedlens;
  }
  if (selectedt4b) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedt4b;
  }
  if (selectedskin) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedskin;
  }
  if (selectedt4c) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedt4c;
  }
  if (selectedt4d) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedt4d;
  }
  if (selectedt4f) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedt4f;
  }
  if (selectedt5aa) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedt5aa;
  }
  if (selectedt5b) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedt5b;
  }
  if (selectedt5c) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedt5c;
  }
  if (selectedt5d) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedt5d;
  }
  if (selectedt5e) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedt5e;
  }
  if (selectedt3f) {
    if (text.length > 0) {
      text += selectedDivider;
    }
    text += selectedt3f;
  }
  if (selectedcomics) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedcomics;
  }
  if (selectedcomicscolors) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedcomicscolors;
  }
  if (selectedcomicsdesign) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedcomicsdesign;
  }
  if (selectedQuality) {
    if (text.length > 0) {
      text += ', ';
    }
    text += selectedQuality;
  }

  for (let key in sortedKeyValues) {
    let k = sortedKeyValues[key][0];
    let v = sortedKeyValues[key][1];
    if (k.startsWith('custom-')) {
      if (text.length > 0) {
        text += ', ';
      }
      text += v;
    }
  }

  if (selectedFormat) {
    if (text.length > 0) {
      text += ' ';
    }
    text += selectedFormat;
  }
  if (selectedWeight) {
    if (text.length > 0) {
      text += ' ';
    }
    text += selectedWeight;
  }
  if (selectedt2a) {
    if (text.length > 0) {
      text += ' ';
    }
    text += selectedt2a;
  }
  if (selectedt2b) {
    if (text.length > 0) {
      text += ' ';
    }
    text += selectedt2b;
  }
  if (selectedt2c) {
    if (text.length > 0) {
      text += ' ';
    }
    text += selectedt2c;
  }
  if (nega) {
    if (text.length > 0) {
      text += ' --no ';
    }
    text += nega;
  }
  if (seed) {
    if (text.length > 0) {
      text += ' --seed ';
    }
    text += seed;
  }
  if (version) {
    if (text.length > 0) {
      text += ' ';
    }
    text += version;
  }

  for (let key in sortedKeyValues) {
    let k = sortedKeyValues[key][0];
    let v = sortedKeyValues[key][1];
    if (k.startsWith('end-')) {
      if (text.length > 0) {
        text += ' ';
      }
      text += v;
    }
  }

  return text;
}

export default updatePrompt;
