import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import materialRoutes from "app/views/material-kit/MaterialRoutes";
import Generator from "./views/generator";
import SavedPrompts from "./views/saved";
import PromptSetting from "./views/prompt_setting";
import EditPanel from "./views/editPanel";
import AccountProfile from "./views/profile";
import Credential from "./views/credential";
import Pricing from "./views/plans";
import Tracker from "./views/tracker";
import PaymentSuccess from "./views/plans/PaymentSuccess";
import PaymentCancel from "./views/plans/PaymentCancel";
import Invoices from "./views/plans/Invoices";
import GenerationHistory from "./views/generation_history/GenerationHistory";
import SavedPresets from "./views/presets";
import WordPressSettings from "./views/wordpress/settings";
import WordpressHistory from "./views/wordpress/history";
import AddImageToPost from "./views/wordpress/singlePostGen";
import MagicGenerator from "./views/magic_generator/MagicGenerator";
import MagicGeneratorMain from "./views/magic_generator/MagicGeneratorMain";
import SmartGenerator from "./views/smart_generator/SmartGenerator";
import Dashboard from "./views/dashboard/Dashboard";

// session pages
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: "/saved",
        element: <SavedPrompts />,
        auth: authRoles.admin,
      },
      {
        path: "/presets",
        element: <SavedPresets />,
        auth: authRoles.admin,
      },

      {
        path: "/prompt_settings",
        element: <PromptSetting />,
        auth: authRoles.admin,
      },

      {
        path: "/editPanel",
        element: <EditPanel />,
        auth: authRoles.admin,
      },

      {
        path: "/account",
        element: <AccountProfile />,
        auth: authRoles.admin,
      },

      {
        path: "/pricing",
        element: <Pricing />,
        auth: authRoles.admin,
      },

      {
        path: "/history",
        element: <GenerationHistory />,
        auth: authRoles.admin,
      },

      {
        path: "/wordpress_sites",
        element: <WordPressSettings />,
        auth: authRoles.admin,
      },
      {
        path: "/add_image_to_post",
        element: <AddImageToPost />,
        auth: authRoles.admin,
      },

      {
        path: "/wordpress_history",
        element: <WordpressHistory />,
        auth: authRoles.admin,
      },
      {
        path: "/credentials",
        element: <Credential />,
        auth: authRoles.admin,
      },

      {
        path: "/prompt_generator",
        element: <Generator />,
        auth: authRoles.admin,
      },

      {
        path: "/magic_generator",
        element: <SmartGenerator/>,
        auth: authRoles.admin,
      },

      {
        path: "/add_image_in_post",
        element: <MagicGenerator />,
        auth: authRoles.admin,
      },

      {
        path: "/add_image_in_post/:siteID/:postID",
        element: <MagicGeneratorMain/>,
        auth: authRoles.admin,
      },

      {
        path: "/dashboard",
        element: <Dashboard />,
        auth: authRoles.admin,
      },

      {
        path: "/track/:trackID",
        element: <Tracker />,
        auth: authRoles.admin,
      },
      {
        path: "/payment/invoices",
        element: <Invoices />,
        auth: authRoles.admin,
      },
    ],
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  // payment confirmation pages
  { path: "/payment/success", element: <PaymentSuccess /> },
  { path: "/payment/cancel", element: <PaymentCancel /> },

  { path: "/", element: <Navigate to="/dashboard" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
