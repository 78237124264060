import {
  AccordionDetails,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useAuth from "app/hooks/useAuth";
import { useEffect, useMemo, useState } from "react";
import VerifyEmail from "../../VerifyEmail";

import Loading from "app/components/MatxLoading";
import { H2, H3, H4 } from "app/components/Typography";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom";
import { db } from "../../../../firebase";
import { Accordion, AccordionSummary } from "../AccordionStyle";
import NotAvailableForPlan from "../NotAvailableForPlan";
import { off } from "firebase/database";
import { LoadingButton } from "@mui/lab";
import SubmitAgainDialog from "./SubmitAgain";
import {
  Delete,
  ReplayCircleFilled,
  RestartAlt,
  Task,
} from "@mui/icons-material";
import { use } from "echarts";
import ClosableInfo from "app/components/ClosableInfo";
import WordpressLimitCrossed from "app/components/WordpressLimitCrossed";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";

export const HISTORY_STATUS = {
  QUEUED: "In Queue",
  PROCESSING: "Processing",
  DONE: "Done",
  ERROR: "Error",
};

const OFFSET_COUNT = 8;

export const StatusBlock = ({ status }) => {
  let color = "#000";
  if (status === HISTORY_STATUS.QUEUED) {
    color = "grey";
  } else if (status === HISTORY_STATUS.PROCESSING) {
    color = "orange";
  } else if (status === HISTORY_STATUS.DONE) {
    color = "green";
  } else if (status === HISTORY_STATUS.ERROR) {
    color = "red";
  }

  return (
    <label
      style={{
        backgroundColor: color,
        fontSize: "10px",
        fontWeight: "bold",
        textTransform: "uppercase",
        color: "white",
        padding: "5px 10px",
        borderRadius: "5px",
      }}
    >
      {status}
    </label>
  );
};

const HistoryItem = ({ image, history, sites, userData }) => {
  const [promptValue, setPromptValue] = useState(image.prompt);
  const [loading, setLoading] = useState(false);
  const [qstring, setQstring] = useState(Date.now());

  useEffect(() => {
    setPromptValue(image.prompt);
  }, [image]);

  const changeImage = async (i) => {
    try {
      setLoading(true);
      const fn = httpsCallable(getFunctions(), "wordpressRequest");
      const obj = {
        command: "changeImage",
        uid: history.uid,
        history_doc: history.id,
        image_doc: image.id,
        selected_image: i,

        site: sites.find((s) => s.id === history.site_doc),
        post: { id: history.post_id },
      };
      console.log(obj);
      const res = await fn(obj);
      setLoading(false);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const regenerateImage = async () => {
    try {
      setLoading(true);
      setQstring((q) => q + 1);
      const fn = httpsCallable(getFunctions(), "wordpressRequest");
      const obj = {
        command: "regenerateImage",
        uid: history.uid,
        history_doc: history.id,
        image_doc: image.id,

        site: sites.find((s) => s.id === history.site_doc),
        post: { id: history.post_id },
        prompt: promptValue,
      };

      if (
        obj.site.settings.selected_dc_cred != -1 &&
        obj.site.settings.selected_dc_cred < userData.dc_creds.length
      )
        obj.site.settings.selected_dc_cred =
          userData.dc_creds[obj.site.settings.selected_dc_cred];
      else obj.site.settings.selected_dc_cred = {};

      console.log(obj);
      const res = await fn(obj);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div style={{ margin: "10px 15px" }}>
      <H3>{image.is_cover ? "Cover Image" : "Article Image"}</H3>

      {image.status === HISTORY_STATUS.DONE && (
        <div>
          <Grid container spacing={2}>
            {image.urls.map((i, id) => (
              <Grid
                item
                xs={6}
                md={3}
                key={id}
                display={"flex"}
                justifyContent={"center"}
              >
                <Link to={i} target="_blank">
                  <img
                    src={i + "?q=" + qstring}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "180px",
                      objectFit: "contain",
                      border:
                        id === image.selected_image
                          ? "4px solid #fcba03"
                          : "none",
                    }}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} marginTop={"10px"}>
            <Grid item xs={12} md={9}>
              <TextField
                multiline
                fullWidth
                rows={3}
                label="Prompt"
                value={promptValue}
                onChange={(e) => setPromptValue(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection="column"
              gap={2}
            >
              <ButtonGroup size="small" fullWidth>
                {[0, 1, 2, 3].map((i) => (
                  <Button
                    key={i}
                    variant="outlined"
                    color={image.selected_image == i ? "secondary" : "primary"}
                    onClick={() => changeImage(i)}
                    disabled={loading}
                  >
                    {i + 1}
                  </Button>
                ))}
              </ButtonGroup>
              <Button
                variant="contained"
                fullWidth
                disabled={loading}
                onClick={regenerateImage}
              >
                Regenerate
              </Button>
            </Grid>
          </Grid>
          <br></br>
        </div>
      )}

      {image.status !== HISTORY_STATUS.DONE && (
        <Stack
          spacing={2}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
          direction="row"
          marginTop={"5px"}
          marginBottom={"5px"}
        >
          <Typography variant="caption" color="text.secondary">
            {image.status_message}
          </Typography>
          <StatusBlock status={image.status} />
        </Stack>
      )}
    </div>
  );
};

const History = ({ userData, history, sites, regenerate }) => {
  const [images, setImages] = useState([]);
  const [liveHistory, setLiveHistory] = useState(history);
  const [showRegenerate, setShowRegenerate] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "wordpress_history", history.id, "images"),
      (snapshot) => {
        let nImages = [];
        snapshot.forEach((doc) => {
          nImages.push({ ...doc.data(), id: doc.id });
        });
        setImages(nImages);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      history.status != HISTORY_STATUS.ERROR
    ) {
      //listen to changes
      const unsubscribe = onSnapshot(
        doc(db, "wordpress_history", history.id),
        (doc) => {
          setLiveHistory(doc.data());
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, []);

  return (
    <Accordion>
      <AccordionSummary>
        <div
          style={{ width: "100%" }}
          onMouseEnter={() => setShowRegenerate(true)}
          onMouseLeave={() => setShowRegenerate(false)}
        >
          <Stack
            spacing={2}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            direction="row"
          >
            <H4>
              <Link to={liveHistory.post_link} target="_blank">
                {history.post_title}
              </Link>
            </H4>
            {showRegenerate && (
              <IconButton
                onClick={regenerate}
                size="small"
                style={{ padding: "0px" }}
              >
                <ReplayCircleFilled />
              </IconButton>
            )}
          </Stack>
          <Stack
            spacing={2}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            direction="row"
            marginTop={"5px"}
          >
            <Typography variant="caption" color="text.secondary">
              {liveHistory.status === HISTORY_STATUS.DONE
                ? `Completed at ${liveHistory.finishedAt
                    .toDate()
                    .toLocaleString()}`
                : liveHistory.status_message}
            </Typography>
            <StatusBlock status={liveHistory.status} />
          </Stack>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <div>
          {images.map((i) => (
            <HistoryItem
              key={i.id}
              image={i}
              history={history}
              sites={sites}
              userData={userData}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const WordPressHistory = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);
  const [sites, setSites] = useState(null);
  const [histories, setHistories] = useState([]);

  const [selectedSite, setSelectedSite] = useState("any");
  const [lastVisible, setLastVisible] = useState(null);
  const [offsetStatus, setOffsetStatus] = useState("idle");

  const [openRegenerate, setOpenRegenerate] = useState(false);
  const [dataRegenerate, setDataRegenerate] = useState({});
  const [reloadHistory, setReloadHistory] = useState(false);
  const [isReaload, setIsReaload] = useState(true);

  const [pushStarting, setPushStarting] = useState(false);
  const [deletingQueued, setDeletingQueued] = useState(false);
  const confirm = useConfirm();

  useEffect(() => {
    const getUserData = async () => {
      //userData
      const dc = await getDoc(doc(getFirestore(), "userData", user.uid));
      setUserData(dc.data());
    };
    getUserData();
  }, []);

  //loads the history on every site change
  useEffect(() => {
    const getAllData = async () => {
      //sites
      setIsReaload(true);
      setOffsetStatus("idle");
      const ref = collection(db, "userData", user.uid, "wordpress_sites");
      const snapshot = await getDocs(ref);
      let nSites = [];
      snapshot.forEach((doc) => {
        nSites.push({ ...doc.data(), id: doc.id });
      });
      setSites(nSites);

      //histories
      let q = query(
        collection(db, "wordpress_history"),
        where("uid", "==", user.uid)
      );

      if (selectedSite !== "any")
        q = query(q, where("site_doc", "==", selectedSite));

      q = query(q, orderBy("createdAt", "desc"), limit(OFFSET_COUNT));

      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length !== 0)
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

      let nHistories = [];
      querySnapshot.forEach((doc) => {
        nHistories.push({ ...doc.data(), id: doc.id });
      });

      setHistories(nHistories);
      setIsReaload(false);
    };

    getAllData();
  }, [reloadHistory, selectedSite]);

  const pushStart = async () => {
    try {
      await confirm({
        confirmationText: "start",
        title: "Confirm Restart",
        description: (
          <div>
            This will first cancel all the items that are currently in the{" "}
            <b>PROCESSING</b> state. Then it will start processing the items in
            the <b>QUEUED</b> state. Are you sure you want to proceed?
          </div>
        ),
      });
      setPushStarting(true);
      const fn = httpsCallable(getFunctions(), "wordpressRequest");
      const obj = {
        command: "pushStartQueue",
      };
      const res = await fn(obj);
      toast.success("Restart was successfull");
    } catch (e) {
      console.log(e);
      
    }
    setPushStarting(false);
  };

  const deleteAllQueued = async () => {
    try {
      await confirm({
        confirmationText: "Yes, delete",
        title: "Confirm Delete",
        description: (
          <div>
            This will delete all the items with status <b>QUEUED</b>,{" "}
            <b>RETRY</b> and <b>SCHEDULED</b> . Are you sure you want to
            proceed?
          </div>
        ),
      });
      setDeletingQueued(true);

      const fn = httpsCallable(getFunctions(), "wordpressRequest");
      const obj = {
        command: "deleteAllQueued",
      };
      const res = await fn(obj);
      toast.success("Queued items deleted successfully");
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
    setDeletingQueued(false);
  };

  const getMoreHistories = useMemo(
    () => async () => {
      if (!lastVisible) return;
      setOffsetStatus("loading");
      //histories
      const q = query(
        collection(db, "wordpress_history"),
        where("uid", "==", user.uid),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(OFFSET_COUNT)
      );

      const querySnapshot = await getDocs(q);

      let nHistories = [];
      querySnapshot.forEach((doc) => {
        nHistories.push({ ...doc.data(), id: doc.id });
      });

      if (nHistories.length === 0) {
        setOffsetStatus("empty");
        setLastVisible(null);
      } else {
        setHistories((h) => [...h, ...nHistories]);
        setOffsetStatus("idle");
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
    },
    [lastVisible]
  );

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  if (!user.plan.wp_rest_api) {
    return <NotAvailableForPlan />;
  }

  return (
    <Box margin={{ xs: "10px", md: "20px" }} width="100%">
      <SubmitAgainDialog
        open={openRegenerate}
        setOpen={setOpenRegenerate}
        data={dataRegenerate}
        reloadHistory={setReloadHistory}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <H2>WordPress history</H2>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          justifyContent={{ xs: "flex-start", md: "flex-end" }}
          display="flex"
          gap={2}
        >
          <Stack spacing={2} direction="row">
            <LoadingButton
              variant="outlined"
              loading={pushStarting}
              startIcon={<RestartAlt />}
              onClick={pushStart}
            >
              Restart Queue
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              loading={deletingQueued}
              color="error"
              startIcon={<Delete />}
              onClick={deleteAllQueued}
            >
              Clear Queue
            </LoadingButton>
          </Stack>

          <TextField
            select
            label="Select Site"
            value={selectedSite}
            onChange={(e) => setSelectedSite(e.target.value)}
            style={{ minWidth: "200px", marginRight: "30px" }}
          >
            <MenuItem value="any">All</MenuItem>
            {sites &&
              sites.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.url.replace("https://", "").replace("/wp-json", "")}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>

      <Box display={"flex"} justifyContent={{ xs: "flex-start", md: "center" }}>
        <Box
          spacing={2}
          width={{ xs: "95%", md: "80%", lg: "70%" }}
          marginTop={"20px"}
          boxSizing="border-box"
        >
          <Stack
            width="100%"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <WordpressLimitCrossed user={user} />
            <ClosableInfo
              name={"wordpress_history"}
              msg={
                "History only persists for two days. Histories that were created more than two days ago will be deleted along with the images automatically."
              }
            />
          </Stack>
          {isReaload && (
            <Box
              sx={{ width: "100%", height: "100px" }}
              display="flex"
              justifyContent="center"
            >
              <Loading />
            </Box>
          )}

          {!isReaload && histories.length === 0 && (
            <Box
              sx={{ width: "100%", height: "100px" }}
              display="flex"
              justifyContent="center"
            >
              <p>No history found</p>
            </Box>
          )}

          <div>
            {!isReaload &&
              histories.length > 0 &&
              histories.map((h) => (
                <History
                  key={h.id}
                  userData={userData}
                  history={h}
                  sites={sites}
                  regenerate={() => {
                    const site = sites.find((s) => s.id === h.site_doc);
                    const post = {
                      id: h.post_id,
                      link: h.post_link,
                      title: h.post_title,
                    };

                    site.settings.selected_dc_cred =
                      userData.dc_creds[site.settings.selected_dc_cred];
                    if (site.settings.selected_dc_cred === -1)
                      site.settings.selected_dc_cred = {};
                    setDataRegenerate({
                      site,
                      post,
                    });
                    setOpenRegenerate(true);
                  }}
                />
              ))}
          </div>

          <Box display="flex" justifyContent="center" marginTop={"20px"}>
            {!isReaload && histories.length > 0 && offsetStatus !== "empty" && (
              <LoadingButton
                loading={offsetStatus === "loading"}
                onClick={getMoreHistories}
                variant="outlined"
              >
                Load More
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WordPressHistory;
