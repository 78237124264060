import React from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import useAuth from 'app/hooks/useAuth';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  Alert,
  Box,
  CardMedia,
  Grid,
  Icon,
  Card,
  Button,
  IconButton,
  Tooltip
} from '@mui/material';
import Loading from 'app/components/MatxLoading';

import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import ImgsViewer from 'react-images-viewer';
import { DownloadForOffline } from '@mui/icons-material';
import download from 'downloadjs';
import { getBlob, getStorage, ref as storageRef } from 'firebase/storage';
import { LoadingButton } from '@mui/lab';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired
};

const Waiting = ({ data }) => {
  if (data && (data.statusCode === 500 || data.statusCode === 401 || data.statusCode === 400)) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          padding: '20px',
          height: '70vh',
          flexDirection: 'column'
        }}
      >
        <Icon style={{ fontSize: '100px', color: 'red' }}>error</Icon>
        <Alert severity="error">
          Task Failed<br></br> {data.status}
        </Alert>
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: '20px',
        height: '70vh'
      }}
    >
      {data === null && <Alert severity="error">Invalid Tracker ID</Alert>}
      {data !== null && !data.status && <Loading />}

      {data && data.status && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
            flexDirection: 'column'
          }}
        >
          {data.progress !== undefined && data.progress > 0 && data.progress < 1 && (
            <CircularProgressWithLabel value={data.progress * 100} />
          )}
          {(data.progress === undefined || data.progress === 0 || data.progress === 1) && (
            <CircularProgress />
          )}

          <Typography
            style={{ marginTop: '15px' }}
            variant="h6"
            component="div"
            color="text.secondary"
          >
            {data.status}
          </Typography>
        </Box>
      )}
    </div>
  );
};

const Tracker = () => {
  const { trackID } = useParams();
  const [data, setData] = useState({});
  const { user } = useAuth();
  const [currImg, setCurrImg] = useState(null);
  const [nowLoading, setNowLoading] = useState([]);

  useEffect(() => {
    if (!trackID || !user || !trackID.includes('-')) {
      setData(null);
      return;
    }
    const db = getDatabase();
    const dt = trackID.split('-');
    const starCountRef = ref(db, `${dt[1]}/${user.uid}/${dt[0]}`);

    const unsub = onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setData(data);
    });

    return () => {
      unsub();
    };
  }, []);

  if (!data || !data.images) return <Waiting data={data} />;

  const images = [];
  Object.keys(data.images).forEach((key) => {
    images.push({ src: data.images[key] });
  });

  return (
    <Box m={{ xs: 2, md: 3 }}>
      <ImgsViewer
        imgs={images}
        currImg={currImg}
        onClickPrev={() => setCurrImg((((currImg - 1) % 4) + 4) % 4)}
        onClickNext={() => setCurrImg((currImg + 1) % 4)}
        isOpen={currImg !== null}
        onClose={() => setCurrImg(null)}
        showCloseBtn={false}
        showThumbnails={true}
        backdropCloseable={true}
      />
      <Grid
        container
        rowSpacing={{ xs: 2, md: 4 }}
        columnSpacing={{ xs: 2, md: 4 }}
        justifyContent={'center'}
      >
        {images.map((img, index) => (
          <Grid item xs={11} md={5} key={index + ''}>
            <Card>
              <CardMedia
                onClick={() => setCurrImg(index)}
                style={{ height: 100, paddingTop: '56.25%' }}
                image={data.images[`img${index + 1}`]}
              />
              <div>
                <div
                  style={{
                    display: 'flex',
                    padding: '8px',
                    justifyContent: 'center'
                  }}
                >
                  <Tooltip title="Coming soon ..." enterTouchDelay={0} placement="top">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        gap: '5px'
                      }}
                    >
                      {/* <FacebookShareButton
                      url={data.images[`img${index + 1}`]}
                      quote={`Check out this image generated by Midjourney `}
                      hashtag="#Alphonse #Midjourney"
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={data.images[`img${index + 1}`]}
                      quote={`Check out this image generated by Midjourney `}
                      hashtag="#Alphonse #Midjourney"
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>

                    <EmailShareButton
                      url={data.images[`img${index + 1}`]}
                      quote={`Check out this image generated by Midjourney `}
                      hashtag="#Alphonse #Midjourney"
                    >
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>

                    <FacebookMessengerShareButton
                      url={data.images[`img${index + 1}`]}
                      quote={`Check out this image generated by Midjourney `}
                      hashtag="#Alphonse #Midjourney"
                    >
                      <FacebookMessengerIcon size={32} round={true} />
                    </FacebookMessengerShareButton>

                    <TwitterShareButton
                      url={data.images[`img${index + 1}`]}
                      quote={`Check out this image generated by Midjourney `}
                      hashtag="#Alphonse #Midjourney"
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton> */}

                      <IconButton>
                        <Tooltip title="upscale" enterTouchDelay={0} placement="bottom">
                          <Icon>arrow_circle_up</Icon>
                        </Tooltip>
                      </IconButton>

                      <IconButton>
                        <Tooltip title="variant" enterTouchDelay={0} placement="bottom">
                          <Icon>call_split</Icon>
                        </Tooltip>
                      </IconButton>
                      <IconButton>
                        <Tooltip title="Download as JPEG" enterTouchDelay={0} placement="bottom">
                          <Icon>web</Icon>
                        </Tooltip>
                      </IconButton>
                    </div>
                  </Tooltip>

                  <LoadingButton
                    loading={nowLoading.includes(index)}
                    disabled={nowLoading.includes(index)}
                    style={{ zIndex: 100 }}
                    onClick={async (e) => {
                      e.stopPropagation();
                      setNowLoading([...nowLoading, index]);
                      let img = data.images[`img${index + 1}`];
                      const sref = storageRef(getStorage(), img);
                      let imgBlob = await getBlob(sref);

                      download(imgBlob, trackID + `img${index + 1}.jpeg`, 'image/jpeg');
                      setNowLoading(nowLoading.filter((i) => i !== index));
                    }}
                  >
                    <DownloadForOffline style={{ fontSize: '28px' }} />
                  </LoadingButton>
                </div>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Tracker;
