import { useState } from 'react';
import { Box, TextField, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { set } from 'lodash';
import { useEffect } from 'react';
import { useRef } from 'react';
import { H3 } from 'app/components/Typography';
import { LoadingButton } from '@mui/lab';
import { db, auth } from '../../../firebase';
import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import useAuth from 'app/hooks/useAuth';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default function AddCredDialog({ setData, op }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [values, setValues] = useState({ name: '', channel_id: '', server_id: '', dc_token: '' });
  const formRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useAuth();

  const handleClose = async () => {
    if (!formRef.current.reportValidity()) return;

    setIsSaving(true);

    let fn = httpsCallable(getFunctions(), 'manageUserData');
    const res = await fn({ command: 'addUserCredential', credentials: values });

    setIsSaving(false);
    setData(values);
  };

  const handleCancel = () => {
    setData(null);
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={op}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Add Credential</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <form ref={formRef}>
              <H3 style={{ minWidth: '500px' }}>Give it a name to recognize</H3>

              <center>
                <TextField
                  fullWidth
                  autoFocus
                  required
                  variant="outlined"
                  placeholder="Name of the Credential"
                  value={values.name}
                  onChange={(e) => setValues({ ...values, name: e.target.value })}
                ></TextField>
              </center>

              <br></br>
              <H3>Credential</H3>

              <center>
                <TextField
                  fullWidth
                  autoFocus
                  required
                  variant="outlined"
                  placeholder="Server ID"
                  value={values.server_id}
                  onChange={(e) => setValues({ ...values, server_id: e.target.value })}
                ></TextField>
              </center>

              <br></br>
              <center>
                <TextField
                  fullWidth
                  autoFocus
                  required
                  variant="outlined"
                  placeholder="Channel ID"
                  value={values.channel_id}
                  onChange={(e) => setValues({ ...values, channel_id: e.target.value })}
                ></TextField>
              </center>

              <br></br>
              <center>
                <TextField
                  fullWidth
                  autoFocus
                  required
                  variant="outlined"
                  placeholder="Discord Token"
                  value={values.dc_token}
                  s
                  onChange={(e) => setValues({ ...values, dc_token: e.target.value })}
                ></TextField>
              </center>
            </form>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">
            Cancel
          </Button>

          <LoadingButton
            loading={isSaving}
            onClick={handleClose}
            color="primary"
            variant="contained"
            autoFocus
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
