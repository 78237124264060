import { useEffect, useState } from 'react';

import useSettings from 'app/hooks/useSettings';

import { db } from '../../../../firebase';

import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';

import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { LightbulbCircleOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { H1 } from 'app/components/Typography';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useAuth from 'app/hooks/useAuth';
import { toast } from 'react-toastify';

const SettingsChooser = ({ data, cancel, reloadHistory }) => {
  const { user } = useAuth();
  const { settings: globalSettings, replaceAllPresets } = useSettings();
  const [settings, setSettings] = useState(data.site.settings);
  const [presets, setPresets] = useState(globalSettings.presets);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPresets = async () => {
      const ref = collection(db, 'userData', user.uid, 'presets');
      const snapshot = await getDocs(ref);
      let nPrompts = [];
      snapshot.forEach((doc) => {
        nPrompts.push({ ...doc.data(), id: doc.id });
      });
      setPresets(nPrompts);
      replaceAllPresets(nPrompts);
    };

    if (!presets) getPresets();
  }, []);

  const submit = async () => {
    setLoading(true);

    try {
      //deep copy
      const obj = JSON.parse(JSON.stringify(data));

      const newSettings = { ...settings };
      newSettings.cover_preset_id = presets.find((p) => p.id === settings.cover_preset_id) || null;
      newSettings.article_preset_id =
        presets.find((p) => p.id === settings.article_preset_id) || null;

      obj.command = 'enquePost';
      obj.site.settings = newSettings;

      console.log('beforesubmit', obj);

      const fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const res = await fn(obj);
      reloadHistory((f) => !f);
      cancel();
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong, please try again later. Please check if your plan supports this feature.");
    }

    setLoading(false);
  };

  return (
    <Stack
      display="flex"
      justifyItems="center"
      justifyContent="center"
      alignItems="center"
      marginTop={'12px'}
    >
      <Stack maxWidth="400px" gap="1.4rem">
        <FormControlLabel
          style={{}}
          control={
            <Checkbox
              onChange={(e) => {
                setSettings({
                  ...settings,
                  remove_images: e.target.checked
                });
              }}
              checked={settings.remove_images}
            />
          }
          label="Remove existing images from post (if any)"
        />

        <TextField
          select
          value={settings.cover_preset_id}
          onChange={(e) => {
            setSettings({
              ...settings,
              cover_preset_id: e.target.value
            });
          }}
          variant="outlined"
          label="Cover Image Preset"
          fullWidth
        >
          <MenuItem value="">Select a preset for cover image</MenuItem>
          {presets &&
            presets.map((preset) => (
              <MenuItem key={preset.id} value={preset.id}>
                {preset.name}
              </MenuItem>
            ))}
        </TextField>

        <TextField
          select
          value={settings.article_preset_id}
          onChange={(e) => {
            setSettings({
              ...settings,
              article_preset_id: e.target.value
            });
          }}
          variant="outlined"
          label="Article Image Preset"
          fullWidth
        >
          <MenuItem value="">Select a preset for article images</MenuItem>
          {presets &&
            presets.map((preset) => (
              <MenuItem key={preset.id} value={preset.id}>
                {preset.name}
              </MenuItem>
            ))}
        </TextField>

        <Stack direction="row" gap="1rem">
          <TextField
            value={settings.image_size[0]}
            onChange={(e) => {
              setSettings({
                ...settings,
                image_size: [parseInt(e.target.value), settings.image_size[1]]
              });
            }}
            variant="outlined"
            label="Image Width"
            fullWidth
            type="number"
          />

          <TextField
            value={settings.image_size[1]}
            onChange={(e) => {
              setSettings({
                ...settings,
                image_size: [settings.image_size[0], parseInt(e.target.value)]
              });
            }}
            variant="outlined"
            label="Image Height"
            fullWidth
            type="number"
          />
        </Stack>
        <Stack gap="1rem" flexDirection="row" alignItems="center">
          <TextField
            select
            value={settings.aspect_ratio}
            onChange={(e) => {
              setSettings({
                ...settings,
                aspect_ratio: e.target.value
              });
            }}
            variant="outlined"
            label="Image Aspect Ratio"
            fullWidth
            style={{ width: '50%' }}
          >
            <MenuItem value="--ar 1:1">1:1</MenuItem>
            <ListSubheader>Horizontal</ListSubheader>
            <MenuItem value="--ar 3:2">3:2</MenuItem>
            <MenuItem value="--ar 16:9">16:9</MenuItem>
            <MenuItem value="--ar 5:4">5:4</MenuItem>
            <MenuItem value="--ar 7:4">7:4</MenuItem>

            <ListSubheader>Vertical</ListSubheader>
            <MenuItem value="--ar 2:3">2:3</MenuItem>
            <MenuItem value="--ar 9:16">9:16</MenuItem>
            <MenuItem value="--ar 4:5">4:5</MenuItem>
            <MenuItem value="--ar 7:4">7:4</MenuItem>
          </TextField>

          <Stack direction="row" alignItems="center" justifyContent="center" width="50%">
            <FormControlLabel
              style={{}}
              control={
                <Checkbox
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      activate_exif: e.target.checked
                    });
                  }}
                  checked={settings.activate_exif}
                />
              }
              label="Activate EXIF"
            />
          </Stack>
        </Stack>

        <FormLabel id="radio-buttons-group-label">Number of Images to Generate</FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value={0}
            control={
              <Radio
                checked={settings.h2_per_image == 0}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    h2_per_image: 0
                  });
                }}
              />
            }
            label="Only Generate the cover photo"
          />
          <FormControlLabel
            value={2}
            control={
              <Radio
                checked={settings.h2_per_image == 2}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    h2_per_image: 2
                  });
                }}
              />
            }
            label="Generate 1 image for every 2 H2 tags"
          />
          <FormControlLabel
            value={3}
            control={
              <Radio
                checked={settings.h2_per_image == 3}
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    h2_per_image: 3
                  });
                }}
              />
            }
            label="Generate 1 image for every 3 H2 tags"
          />
        </RadioGroup>

        <Button variant="outlined" onClick={cancel}>
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          fullWidth
          color="primary"
          startIcon={<LightbulbCircleOutlined />}
          loading={loading}
          onClick={submit}
        >
          START GENERATING
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

const SubmitAgainDialog = ({ open, setOpen, data, reloadHistory }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Review Settings for Generation</DialogTitle>
      <DialogContent>
        <SettingsChooser data={data} cancel={() => setOpen(false)} reloadHistory={reloadHistory} />
      </DialogContent>
    </Dialog>
  );
};

export default SubmitAgainDialog;
