import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Loading from 'app/components/MatxLoading';
import { H2 } from 'app/components/Typography';

import { doc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../../firebase';
import { Settings } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { settings } from 'firebase/analytics';

const SiteSettings = ({ userData, presets, uid }) => {
  const [defaultSettings, setDefaultSettings] = useState({
    openai_key: '',
    selected_dc_cred: -1,
    cover_preset_id: '',
    article_preset_id: '',
    image_size: [1024, 1024],
    activate_exif: true,
    aspect_ratio: '--ar 16:9',
    h2_per_image: 2,
    remove_images: true
  });
  const [loadingSave, setLoadingSave] = useState(false);
  const [showDefaultSettings, setShowDefaultSettings] = useState(false);

  useEffect(() => {
    if (userData?.default_wp_settings) {
      setDefaultSettings(userData.default_wp_settings);
    }
  }, [userData]);

  const saveSettings = async () => {
    setLoadingSave(true);
    const ref = doc(db, 'userData', uid);
    await updateDoc(ref, {
      default_wp_settings: defaultSettings
    });
    setLoadingSave(false);
  };

  if (!userData) {
    return <Loading />;
  }

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="1rem"
        onClick={() => setShowDefaultSettings(!showDefaultSettings)}
      >
        <H2 style={{ cursor: 'pointer' }}>Default Settings </H2>
        <IconButton>
          <Settings />
        </IconButton>
      </Stack>
      <br></br>
      <Stack
        display={showDefaultSettings ? 'flex' : 'none'}
        justifyItems="center"
        justifyContent="center"
        alignItems="center"
      >
        <Stack maxWidth="500px" gap="1.4rem">
          <FormControlLabel
            style={{}}
            control={
              <Checkbox
                onChange={(e) => {
                  setDefaultSettings({
                    ...defaultSettings,
                    remove_images: e.target.checked
                  });
                }}
                checked={defaultSettings.remove_images}
              />
            }
            label="Remove existing images from post (if any)"
          />

          <TextField
            onFocus={(e) => {
              e.target.type = 'text';
            }}
            onBlur={(e) => {
              e.target.type = 'password';
            }}
            type="password"
            value={defaultSettings.openai_key}
            onChange={(e) => {
              setDefaultSettings({
                ...defaultSettings,
                openai_key: e.target.value
              });
            }}
            variant="outlined"
            name="openai_key"
            label="OpenAI API Key"
            fullWidth
          />

          <TextField
            select
            value={defaultSettings.selected_dc_cred}
            onChange={(e) => {
              setDefaultSettings({
                ...defaultSettings,
                selected_dc_cred: e.target.value
              });
            }}
            variant="outlined"
            label="Discord Credential"
            fullWidth
          >
            <MenuItem value={-1}>Please select a credential</MenuItem>
            {userData.dc_creds.map((cred, index) => (
              <MenuItem key={index} value={index}>
                {cred.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            value={defaultSettings.cover_preset_id}
            onChange={(e) => {
              setDefaultSettings({
                ...defaultSettings,
                cover_preset_id: e.target.value
              });
            }}
            variant="outlined"
            label="Cover Image Preset"
            fullWidth
          >
            <MenuItem value="">Select a preset for cover image</MenuItem>
            {presets &&
              presets.map((preset) => (
                <MenuItem key={preset.id} value={preset.id}>
                  {preset.name}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            select
            value={defaultSettings.article_preset_id}
            onChange={(e) => {
              setDefaultSettings({
                ...defaultSettings,
                article_preset_id: e.target.value
              });
            }}
            variant="outlined"
            label="Article Image Preset"
            fullWidth
          >
            <MenuItem value="">Select a preset for article images</MenuItem>
            {presets &&
              presets.map((preset) => (
                <MenuItem key={preset.id} value={preset.id}>
                  {preset.name}
                </MenuItem>
              ))}
          </TextField>

          <Stack direction="row" gap="1rem">
            <TextField
              value={defaultSettings.image_size[0]}
              onChange={(e) => {
                setDefaultSettings({
                  ...defaultSettings,
                  image_size: [parseInt(e.target.value), defaultSettings.image_size[1]]
                });
              }}
              variant="outlined"
              label="Image Width"
              fullWidth
              type="number"
            />

            <TextField
              value={defaultSettings.image_size[1]}
              onChange={(e) => {
                setDefaultSettings({
                  ...defaultSettings,
                  image_size: [defaultSettings.image_size[0], parseInt(e.target.value)]
                });
              }}
              variant="outlined"
              label="Image Height"
              fullWidth
              type="number"
            />
          </Stack>

          <Stack gap="1rem" flexDirection="row" alignItems="center">
            <TextField
              select
              value={defaultSettings.aspect_ratio}
              onChange={(e) => {
                setDefaultSettings({
                  ...defaultSettings,
                  aspect_ratio: e.target.value
                });
              }}
              variant="outlined"
              label="Image Aspect Ratio"
              fullWidth
              style={{ width: '50%' }}
            >
              <MenuItem value="--ar 1:1">1:1</MenuItem>
              <ListSubheader>Horizontal</ListSubheader>
              <MenuItem value="--ar 3:2">3:2</MenuItem>
              <MenuItem value="--ar 16:9">16:9</MenuItem>
              <MenuItem value="--ar 5:4">5:4</MenuItem>
              <MenuItem value="--ar 7:4">7:4</MenuItem>

              <ListSubheader>Vertical</ListSubheader>
              <MenuItem value="--ar 2:3">2:3</MenuItem>
              <MenuItem value="--ar 9:16">9:16</MenuItem>
              <MenuItem value="--ar 4:5">4:5</MenuItem>
              <MenuItem value="--ar 7:4">7:4</MenuItem>
            </TextField>

            <Stack direction="row" alignItems="center" justifyContent="center" width="50%">
              <FormControlLabel
                style={{}}
                control={
                  <Checkbox
                    onChange={(e) => {
                      setDefaultSettings({
                        ...defaultSettings,
                        activate_exif: e.target.checked
                      });
                    }}
                    checked={defaultSettings.activate_exif}
                  />
                }
                label="Activate EXIF"
              />
            </Stack>
          </Stack>
          <FormLabel id="radio-buttons-group-label">Number of Images to Generate</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value={0}
              control={
                <Radio
                  checked={defaultSettings.h2_per_image == 0}
                  onChange={(e) => {
                    setDefaultSettings({
                      ...defaultSettings,
                      h2_per_image: 0
                    });
                  }}
                />
              }
              label="Only Generate the cover photo"
            />
            <FormControlLabel
              value={2}
              control={
                <Radio
                  checked={defaultSettings.h2_per_image == 2}
                  onChange={(e) => {
                    setDefaultSettings({
                      ...defaultSettings,
                      h2_per_image: 2
                    });
                  }}
                />
              }
              label="Generate 1 image for every 2 H2 tags"
            />
            <FormControlLabel
              value={3}
              control={
                <Radio
                  checked={defaultSettings.h2_per_image == 3}
                  onChange={(e) => {
                    setDefaultSettings({
                      ...defaultSettings,
                      h2_per_image: 3
                    });
                  }}
                />
              }
              label="Generate 1 image for every 3 H2 tags"
            />
          </RadioGroup>

          <LoadingButton
            onClick={saveSettings}
            loading={loadingSave}
            variant="contained"
            color="primary"
          >
            Save Default Settings
          </LoadingButton>
        </Stack>
        <br></br>
        <br></br>
      </Stack>
    </div>
  );
};

export default SiteSettings;
