import useAuth from "app/hooks/useAuth";
import useSettings from "app/hooks/useSettings";
import { db } from "../../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import Loading from "app/components/MatxLoading";
import {
  Alert,
  Button,
  Grid,
  ListSubheader,
  MenuItem,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../wordpress/AccordionStyle";
import { LoadingButton } from "@mui/lab";
import UpgradeDialog from "../UpgradePlanDialog";
import GenerationTracker from "./GenerationTracker";

const Generator = ({
  siteID,
  postID,
  item,
  presets,
  isExpanded,
  setMsg,
  setPlanMsg,
}) => {
  const { user } = useAuth();
  const [expanded, setExpanded] = useState(false);
  const [GPTresult, setGPTresult] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(-1);
  const [selectedPrompt, setSelectedPrompt] = useState(0);
  const [selectedAR, setSelectedAR] = useState("--ar 3:2");
  const [prompt, setPrompt] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationTracker, setGenerationTracker] = useState(null);

  useEffect(() => {
    if (expanded) {
      const fetchData = async () => {
        const fn = httpsCallable(getFunctions(), "magicGenerator");
        const data = await fn({
          type: "generateGPTIdea",
          site_id: siteID,
          prompt: "Title: " + item.headline + "\n\n" + item.text,
        });

        setGPTresult(data.data);
      };

      fetchData();
    }
  }, [expanded]);

  useEffect(() => {
    if (isExpanded) setExpanded(true);
  }, [isExpanded]);

  useEffect(() => {
    if (!GPTresult) return;

    let newPrompt = GPTresult[selectedPrompt];

    if (selectedPreset >= 0) {
      const preset = presets[selectedPreset].preset;
      newPrompt = preset.replace("[SUBJECT]", newPrompt);
    }

    if (newPrompt.includes("[SIZE]")) {
      newPrompt = newPrompt.replace("[SIZE]", selectedAR);
    } else {
      newPrompt += " " + selectedAR;
    }

    setPrompt(newPrompt);
  }, [selectedPrompt, selectedPreset, selectedAR, GPTresult]);

  if (!GPTresult)
    return (
      <Stack width="100%">
        <Loading />
      </Stack>
    );

  const generateHandler = async () => {
    if (!user.plan.saas.generator) return;

    setIsGenerating(true);

    try {
      const fn = httpsCallable(getFunctions(), "generateImage");
      const { data } = await fn({ prompt });
      if (data.error) {
        console.log({ data });
        if (data.error?.includes("Rate limit")) {
          setPlanMsg(
            "You have reached your daily limit. Please upgrade your plan to continue."
          );
          setIsGenerating(false);
          return;
        } else setMsg({ msg: data.error, sv: "error" });
        setIsGenerating(false);
        return;
      } else {
        setGenerationTracker(data);
      }
    } catch (err) {
      console.log({ err });
      setMsg({
        msg: "Something went wrong. Please try again later",
        sv: "error",
      });
    } finally {
      setIsGenerating(false);
    }
  };
  return (
    <Stack width="100%">
      <GenerationTracker
        tracker={generationTracker}
        postID={postID}
        siteID={siteID}
      />
      <div>
        <Tabs
          value={selectedPrompt}
          onChange={(e, v) => setSelectedPrompt(v)}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ width: "100%" }}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          <Tab style={{ width: "20%" }} label="Prompt 1" />
          <Tab style={{ width: "20%" }} label="Prompt 2" />
          <Tab style={{ width: "20%" }} label="Prompt 3" />
          <Tab style={{ width: "20%" }} label="Prompt 4" />
          <Tab style={{ width: "20%" }} label="Prompt 5" />
        </Tabs>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <TextField
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              multiline
              maxRows={5}
              rows={4}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Stack
              height="100%"
              padding="8px 2px"
              justifyContent="space-between"
              spacing={1}
            >
              <Stack spacing={1} direction="row">
                <TextField
                  select
                  value={selectedPreset}
                  onChange={(e) => setSelectedPreset(e.target.value)}
                  variant="outlined"
                  label="Presets"
                  fullWidth
                >
                  <MenuItem value={-1}>None</MenuItem>
                  {presets &&
                    presets.map((preset, i) => (
                      <MenuItem key={preset.id} value={i}>
                        {preset.name}
                      </MenuItem>
                    ))}
                </TextField>

                <TextField
                  select
                  value={selectedAR}
                  onChange={(e) => setSelectedAR(e.target.value)}
                  variant="outlined"
                  label="Aspect Ratio"
                  fullWidth
                  style={{ width: "50%" }}
                >
                  <MenuItem value="--ar 1:1">1:1</MenuItem>
                  <ListSubheader>Horizontal</ListSubheader>
                  <MenuItem value="--ar 3:2">3:2</MenuItem>
                  <MenuItem value="--ar 16:9">16:9</MenuItem>
                  <MenuItem value="--ar 5:4">5:4</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>

                  <ListSubheader>Vertical</ListSubheader>
                  <MenuItem value="--ar 2:3">2:3</MenuItem>
                  <MenuItem value="--ar 9:16">9:16</MenuItem>
                  <MenuItem value="--ar 4:5">4:5</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>
                </TextField>
              </Stack>

              <LoadingButton
                onClick={generateHandler}
                loading={isGenerating}
                disabled={!user.plan.saas.generator || isGenerating}
                fullWidth
                variant="contained"
              >
                Generate Image
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </Stack>
  );
};

const MagicGeneratorMain = () => {
  const { siteID, postID } = useParams();
  const { user } = useAuth();

  const { settings, replaceAllPresets } = useSettings();
  const [presets, setPresets] = useState(settings.presets);
  const [postData, setPostData] = useState(null);
  const [expanded, setExpanded] = useState(-1);
  const [msg, setMsg] = useState(null);
  const [planMsg, setPlanMsg] = useState(null);

  useEffect(() => {
    const getPresets = async () => {
      const ref = collection(db, "userData", user.uid, "presets");
      const snapshot = await getDocs(ref);
      let nPrompts = [];
      snapshot.forEach((doc) => {
        nPrompts.push({ ...doc.data(), id: doc.id });
      });
      setPresets(nPrompts);
      replaceAllPresets(nPrompts);
    };

    const getPostData = async () => {
      const fn = httpsCallable(getFunctions(), "magicGenerator");
      const res = await fn({
        type: "initial",
        site_id: siteID,
        post_id: postID,
      });

      setPostData(res.data);
    };

    if (!presets) getPresets();

    getPostData();
  }, []);

  if (postData === null) return <Loading />;

  return (
    <Stack justifyContent={"center"} alignItems={"center"}>
      <Typography variant="h4">Magic Generator</Typography>
      <br></br>
      <Snackbar
        open={!!msg}
        autoHideDuration={3000}
        onClose={() => setMsg(null)}
        sx={{ height: "15%" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert sx={{ m: 1 }} severity={msg?.sv} variant="filled">
          {msg?.msg}
        </Alert>
      </Snackbar>

      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        maxWidth="1000px"
        spacing={2}
      >
        {postData.map((item, i) => (
          <Accordion
            style={{ width: "100%" }}
            key={i}
            expanded={expanded === i}
            onChange={() => setExpanded(expanded === i ? -1 : i)}
          >
            <AccordionSummary>
              <Typography variant="h5">
                {item.headline}
                {item.position == -1 && " (Cover)"}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Generator
                item={item}
                presets={presets}
                siteID={siteID}
                postID={postID}
                isExpanded={expanded === i}
                setMsg={setMsg}
                setPlanMsg={setPlanMsg}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Stack>
  );
};

export default MagicGeneratorMain;
