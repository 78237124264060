import { Box, styled } from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import useSettings from 'app/hooks/useSettings';
import { useEffect, useState } from 'react';
import VerifyEmail from '../../VerifyEmail';

import { H2 } from 'app/components/Typography';
import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';
import { db } from '../../../../firebase';
import NotAvailableForPlan from '../NotAvailableForPlan';
import DefaultSiteSettings from './DefaultSiteSettings';
import SingleSiteSettings from './SingleSiteSettings';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  position: 'relative'
}));

const CardDiv = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  padding: '25px',
  boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
  borderRadius: '5px',
  flexDirection: 'column',
  width: '100%'
}));

const WordPressSettings = () => {
  const { user } = useAuth();

  const { settings, replaceAllPresets } = useSettings();
  const [presets, setPresets] = useState(settings.presets);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      const dc = await getDoc(doc(getFirestore(), 'userData', user.uid));
      setUserData(dc.data());
    };

    const getPresets = async () => {
      const ref = collection(db, 'userData', user.uid, 'presets');
      const snapshot = await getDocs(ref);
      let nPrompts = [];
      snapshot.forEach((doc) => {
        nPrompts.push({ ...doc.data(), id: doc.id });
      });
      setPresets(nPrompts);
      replaceAllPresets(nPrompts);
    };

    if (!presets) getPresets();

    getUserData();
  }, []);

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  if (!user.plan.wp_rest_api) {
    return <NotAvailableForPlan />;
  }

  return (
    <div>
      <FlexBox style={{ marginTop: '25px', marginBottom: '35px' }}>
        <H2 style={{ position: 'absolute', top: '-15px', left: '12px' }}>
          WordPress Sites Settings
        </H2>
      </FlexBox>

      <FlexBox>
        <Box m={{ xs: 2, sm: 4, md: 5 }} width={{ xs: '90%', md: '80%', lg: '60%' }}>
          <CardDiv>
            <DefaultSiteSettings userData={userData} presets={presets} uid={user.uid} />
          </CardDiv>
          <br></br>
          <CardDiv>
            <SingleSiteSettings userData={userData} presets={presets} uid={user.uid} />
          </CardDiv>
        </Box>
      </FlexBox>
    </div>
  );
};

export default WordPressSettings;
